<template>
  <div class="ingresos">
 <ingreCaja></ingreCaja>
       
  </div>
</template>

<script>
// @ is an alias to /src
import ingreCaja from '@/components/ingresos_caja.vue'




export default {
  name: 'Ingresos_Caja',
  components: {
    ingreCaja

    
  

    
  }
}
</script>